import { makeStyles } from '@material-ui/core/styles';
import theme from "../config/Theme";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    backendErrors:{
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        margin: 'auto'
    },
    infoHover:{
        padding: theme.spacing(1),
    },
}),{defaultTheme: theme});

export { useStyles };

