import APIKit from './Base'
import { logBackend } from './LoggingApi'
import { InternalCodeDto, StandardDto } from './StandardApi';

export interface LinkGroupDto{
    idProduct?:number,
    username?:number,
    created?:string,
    mdaFilter?:InternalCodeDto,
    mdnFilter?:InternalCodeDto,
    mdsFilter?:InternalCodeDto[],
    mdtFilter?:InternalCodeDto[],
    emdnFilter?:InternalCodeDto,
    fulltextSearch?:string,
    standards?:StandardDto[],
    removedStandards?:StandardDto[],
}

export interface LinkGroupCreateDto{
    idProduct?:number,
    mdaFilter?:number,
    mdnFilter?:number,
    mdsFilter?:number[],
    mdtFilter?:number[],
    emdnFilter?:number,
    fulltextSearch?:string,
    standards?:number[],
    removedStandards?:number[],
}

export const getLatestLinkGroup = (dtoSwitcher,idProduct) => {
    return APIKit.get("/linkgroup/"+idProduct, { params: { dtoSwitcher: dtoSwitcher } })
    .then<LinkGroupDto>(response => {
        return response.data;
    })
    .catch(err => {
        throw(err)
    })
}

export const countLinkGroups = (idProduct) =>{
    return APIKit.get("/linkgroup/count", { params: { idProduct: idProduct}})
        .then<number>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const findLinkGroups = (dtoSwitcher, limit, offset, idProduct, newestFirst) =>{
    return APIKit.get("/linkgroup", { params: { dtoSwitcher: dtoSwitcher, limit: limit, offset: offset, idProduct: idProduct, newestFirst: newestFirst }})
        .then<LinkGroupDto[]>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const createLinkGroup = (linkGroup: LinkGroupCreateDto) =>{
    return APIKit.post("/linkgroup", linkGroup)
        .then<LinkGroupDto>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const getPDFByLinkGroupId = (idLinkGroup,languageSwitch) => {
    return APIKit.get("/linkgroup/download", { responseType: 'arraybuffer',params:{idLinkGroup:idLinkGroup,languageSwitch:languageSwitch} })
    .then(response => {
        return response.data;
    })
    .catch(err => {
        throw(err)
    })
}