import React, {useEffect, useState} from 'react'
import {Card, CardActions, CardContent, ListItemText, Paper, TableCell, Typography, ListItem} from "@material-ui/core";
import {ProductDto, findProductById, deleteProductById} from "../../api/ProductApi";
import {navigate, Link} from "gatsby";
import ConfirmDialog from "../common/ConfirmDialog";
import ButtonStyled from "../common/ButtonStyled";
import Header from "../common/Header";

import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import BackendError from "../common/BackendError";
import { getLatestLinkGroup, LinkGroupDto } from '../../api/LinkGroupApi';

interface ProductDetailProps{
    id: number,
    style?: any
}
export default function ProductDetail( props: ProductDetailProps){
    const classes = useStyles();
    const { t } = useTranslation();

    const [product, setProduct] = useState<ProductDto>(null)
    const [backendErrorMessage, setBackendErrorMessage] = useState(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [latestLinkning, setLatestLinking] = useState<LinkGroupDto>(null)

    const deleteProduct = () => {
        deleteProductById(props.id).then((status) => {
            navigate(-1)
        }).catch(err=> {
            (err.response!=null && err.response.data.message!=null) ? setBackendErrorMessage(err.response.data.message) : setBackendErrorMessage(t("ERRDELETEPRODUCT"))
            setDeleteDialogOpen(false)
        })
    }

    useEffect(() => {
        findProductById(props.id).then(data =>{
            setProduct(data)
        }).catch(err => {
            console.log("Error getting product with id " + props.id, err)
            navigate("/404")
        })
        getLatestLinkGroup(1,props.id).then(data =>{
            setLatestLinking(data)
        }).catch(err => {
            setLatestLinking(null)
        })
    },[])

    return (
        <Paper className={classes.root} style={props.style}>
            {product!=null ?
                <Card variant="outlined">
                    <Header title={t("PRODUCTDETAIL")}/>
                    <CardContent>
                        {backendErrorMessage!=null && <BackendError error={backendErrorMessage}/>}
                        <div style={{display:"flex",justifyContent:"space-between", alignItems:"center"}}>
                            <div>
                                <Typography className={classes.fieldTitle} variant="h6">
                                    {t("NAME")}
                                </Typography>
                                <Typography className={classes.fieldContent} variant="body1">
                                    {product.name}
                                </Typography>
                                <Typography className={classes.fieldTitle} variant="h6">
                                    {t("MANUFACTURERCOMPANY")}
                                </Typography>
                                <Typography className={classes.fieldContent} variant="body1">
                                    {product.manufacturer}
                                </Typography>
                                <Typography className={classes.fieldTitle} variant="h6">
                                    {t("ION")}
                                </Typography>
                                <Typography className={classes.fieldContent} variant="body1">
                                    {(product.internalOrderNumber!=null && product.internalOrderNumber.length!=0) ? product.internalOrderNumber : t("PRODUCTHASNO")+t("ION")}
                                </Typography>
                                <Typography className={classes.fieldTitle} variant="h6">
                                    {t("NOTE")}
                                </Typography>
                                <Typography className={classes.fieldContent} variant="body1">
                                    {product.note.length!=0 ? product.note : t("PRODUCTHASNO")+t("NOTE")}
                                </Typography>
                                <Typography className={classes.fieldTitle} variant="h6">
                                    {t("LASTUPDATETIME")}
                                </Typography>
                                <Typography className={classes.fieldContent} variant="body1">
                                    {product.lastChanged}
                                </Typography>
                                <Typography className={classes.fieldTitle} variant="h6">
                                    {t("LASTUPDATEAUTHOR")}
                                </Typography>
                                <Typography className={classes.fieldContent} variant="body1">
                                    {product.userChanged}
                                </Typography>
                                {/*
                                <Typography className={classes.fieldTitle} variant="h6">
                                    Keywords
                                </Typography>
                                <Typography className={classes.fieldContent} variant="body1">
                                    {product.keywords.length!=0 ? product.keywords.map(keyword=>keyword.name).join(", ") : "This product has no keywords."}
                                </Typography>
                                */}
                            </div>
                            <div>
                                {latestLinkning!=null ?
                                    <div style={{cursor:"pointer", width:300,marginRight:40}} onClick={() => navigate("/products/"+props.id+"/link-history?latest")}>
                                        <Typography className={classes.fieldTitle} variant="h6">
                                            {t("LATESTLINKING")}
                                        </Typography>
                                        <ListItem><ListItemText primary={t("AUTHOR")+": "+latestLinkning.username}/></ListItem>
                                        <ListItem><ListItemText primary={t("STANDARDSINCLUDEDCOUNT")+": "+latestLinkning.standards.length}/></ListItem>
                                        <ListItem><ListItemText primary={t("STANDARDSREMOVEDCOUNT")+": "+latestLinkning.removedStandards.length}/></ListItem>
                                    </div>
                                :
                                    <div style={{width:300,marginRight:40}}>
                                        <Typography className={classes.fieldTitle} variant="h6">
                                            {t("LATESTLINKING")}
                                        </Typography>
                                        <ListItem><ListItemText primary={t("NOLINKINGS")}/></ListItem>
                                    </div>
                                }
                            </div>
                        </div>
                    </CardContent>
                    <CardActions style={{justifyContent:"space-between", flexFlow:"row"}} className={classes.cardActions}>
                        <div>
                            <ButtonStyled
                                text={t("DELETE")}
                                style={{marginRight: 10}}
                                onClick={() => setDeleteDialogOpen(true)}/>
                            <ConfirmDialog
                                title={t("DELETEPRODUCT")}
                                open={deleteDialogOpen}
                                onClose={()=>setDeleteDialogOpen(false)}
                                onConfirm={deleteProduct}
                            >
                                {t("DELETEPRODUCTCONFIRM",{productName: product.name})}
                            </ConfirmDialog>
                            <ButtonStyled
                                text={t("EDIT")}
                                onClick={() => navigate("/products/"+props.id+"/edit")}/>
                        </div>
                        <div>
                            <ButtonStyled
                                text={t("LINKSTANDARDS")}
                                style={{marginRight: 10}}
                                onClick={() => navigate("/products/"+props.id+"/linking")}/>
                            <ButtonStyled
                                text={t("LINKHISTORY")}
                                onClick={() => navigate("/products/"+props.id+"/link-history")}/>
                        </div>
                    </CardActions>        
                </Card>
                : <div/>}
        </Paper>
    )
}