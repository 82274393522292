import React, {ReactNode} from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import ButtonStyled from "./ButtonStyled";
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps{
    title:string,
    open:any,
    children?: ReactNode,
    onClose:any,
    onConfirm:any,
}
export default function ConfirmDialog( props: ConfirmDialogProps){
    const { t } = useTranslation();
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{props.title}</DialogTitle>
            <DialogContent>{props.children}</DialogContent>
            <DialogActions>
                <ButtonStyled text={t("NO")} onClick={props.onClose}/>
                <ButtonStyled text={t("YES")} onClick={props.onConfirm}/>
            </DialogActions>
        </Dialog>
    )
}