import React from 'react';
import Layout from '../../../components/Layout';

import ProductDetail from "../../../components/products/ProductDetail";

export default function ProductDetailPage( props ) {

    return (
        <Layout allowAnonymous={false}>
            <ProductDetail style={{minWidth:700, width:"70%", marginLeft: "auto", marginRight: "auto"}} id={props.params.productId}/>
        </Layout>
    )
}