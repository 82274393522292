import React from 'react'
import { Alert, AlertTitle } from "@material-ui/lab";
import { useStyles } from "../../styles/InfoErrorsStyles";

interface BackendErrorProps{
    error:string,
}

export default function BackendError( props: BackendErrorProps){
    const classes = useStyles();

    return (
        <Alert className={classes.backendErrors} severity="error">
            {props.error}
        </Alert>
    )
}